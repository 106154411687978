import React from "react";
import { AspectRatio } from "theme-ui";
import { motion } from "framer-motion";
import { useResponsiveValue } from "../../hooks/breakpoints";

const CoreAspectRatio = React.forwardRef(
  ({ ratio = [], ...props }, forwardedRef) => {
    const localRatio = useResponsiveValue(
      Array.isArray(ratio) ? ratio : [ratio]
    );

    return <AspectRatio ref={forwardedRef} ratio={localRatio} {...props} />;
  }
);

const MotionAspectRatio = motion.custom(CoreAspectRatio);
export { CoreAspectRatio as AspectRatio, MotionAspectRatio };

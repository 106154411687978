const lightCyan = {
  50: "#fafdfd",
  100: "#f2fbfb",
  200: "#eaf8f9",
  300: "#e2f5f6",
  400: "#dbf3f4",
  500: "#d5f1f2",
  600: "#d0eff0",
  700: "#caedee",
  800: "#c4ebec",
  900: "#bae7e8",
  A100: "#ffffff",
  A200: "#ffffff",
  A400: "#ffffff",
  A700: "#ffffff",
  contrastDefaultColor: "dark",
};

const maximumBlueGreen = {
  50: "#e7f7f7",
  100: "#c2ebeb",
  200: "#99ddde",
  300: "#70cfd1",
  400: "#52c5c7",
  500: "#33bbbd",
  600: "#2eb5b7",
  700: "#27acae",
  800: "#20a4a6",
  900: "#149698",
  A100: "#ccfeff",
  A200: "#99fdff",
  A400: "#66fbff",
  A700: "#4dfbff",
  contrastDefaultColor: "dark",
};

const charcoal = {
  50: "#e3e7e9",
  100: "#bac4c9",
  200: "#8c9da5",
  300: "#5d7680",
  400: "#3b5865",
  500: "#183b4a",
  600: "#153543",
  700: "#112d3a",
  800: "#0e2632",
  900: "#081922",
  A100: "#60bcff",
  A200: "#2da7ff",
  A400: "#0090f9",
  A700: "#0081e0",
  contrastDefaultColor: "light",
};

const indigoDie = {
  50: "#e2e9ed",
  100: "#b6c9d1",
  200: "#86a5b3",
  300: "#558194",
  400: "#30667d",
  500: "#0c4b66",
  600: "#0a445e",
  700: "#083b53",
  800: "#063349",
  900: "#032338",
  A100: "#6ebeff",
  A200: "#3ba7ff",
  A400: "#0890ff",
  A700: "#0083ee",
  contrastDefaultColor: "light",
};

export { maximumBlueGreen, indigoDie, charcoal, lightCyan };

import React from "react";
import useThemeColor from "../../hooks/useThemeColor";
import { Svg } from "../core";

function Location({ color = "primary", height = "16px", width = "12px" }) {
  const localColor = useThemeColor(color);

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        position: "relative",
        top: "-1px",
      }}
    >
      <path
        fill={localColor}
        d="M5.9375 0C2.65963 0 0 2.39393 0 5.34375C0 10.0938 5.9375 16.625 5.9375 16.625C5.9375 16.625 11.875 10.0938 11.875 5.34375C11.875 2.39393 9.21537 0 5.9375 0ZM5.9375 8.3125C5.46777 8.3125 5.00859 8.17321 4.61802 7.91224C4.22745 7.65127 3.92304 7.28035 3.74329 6.84637C3.56353 6.4124 3.51649 5.93487 3.60813 5.47416C3.69977 5.01346 3.92597 4.59027 4.25812 4.25812C4.59027 3.92597 5.01346 3.69977 5.47416 3.60813C5.93487 3.51649 6.4124 3.56353 6.84637 3.74329C7.28035 3.92304 7.65127 4.22745 7.91224 4.61802C8.17321 5.00859 8.3125 5.46777 8.3125 5.9375C8.31181 6.56718 8.06137 7.17087 7.61612 7.61612C7.17087 8.06137 6.56718 8.31181 5.9375 8.3125Z"
      />
    </Svg>
  );
}

export { Location };

import React, { useState } from "react";
import { motion } from "framer-motion";
import { jsx, useThemeUI, Box } from "theme-ui";
import { InputTextContext } from "./InputContext";
import { Field, BaseInput } from "./components";

/** @jsx jsx */

const CoreTextInput = React.forwardRef(
  (
    {
      className,
      children,
      variant = "default",
      name,
      value,
      placeholder,
      readOnly = false,
      disabled = false,
      required = false,
      type = "text",
      onFocus,
      onBlur,
      onChange,
      style,
    },
    forwardedRef
  ) => {
    const { theme } = useThemeUI();
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e) => {
      if (!disabled && !readOnly) {
        setIsFocused(true);
      }
      onFocus && onFocus(e);
    };

    const handleBlur = (e) => {
      setIsFocused(false);
      onBlur && onBlur(e);
    };

    return (
      <Box
        className={className}
        ref={forwardedRef}
        sx={{
          display: "inline-flex",
          width: "100%",
          ...theme.forms[variant],
          ...style,
          //borderColor: isFocused && theme.colors["primary"],
        }}
        onClick={handleFocus}
      >
        <InputTextContext.Provider
          value={{
            value,
            type,
            placeholder,
            name,
            isFocused,
            disabled,
            readOnly,
            required,
            onFocus: handleFocus,
            onBlur: handleBlur,
            onChange,
          }}
        >
          {children ?? <BaseInput />}
        </InputTextContext.Provider>
      </Box>
    );
  }
);

CoreTextInput.Field = Field;

const MotionTextInput = motion.custom(CoreTextInput);
export { CoreTextInput as TextInput, MotionTextInput };

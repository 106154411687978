import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Avatar = ({ image, alt, style }) => (
  <GatsbyImage
    image={image}
    alt={alt}
    style={{
      boxSizing: "border-box",
      margin: 0,
      minWidth: 0,
      maxWidth: "100%",
      height: "auto",
      borderRadius: "50%",
      overflow: "hidden",
      zIndex: 1,
      ...style,
    }}
  />
);

export { Avatar };

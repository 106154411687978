export function firstLoad() {
  const add = () => {
    document.getElementById("___gatsby").classList.add("firstLoad");
  };

  const remove = () => {
    document.getElementById("___gatsby").classList.remove("firstLoad");
  };

  const state =
    typeof document !== "undefined"
      ? document.getElementById("___gatsby").classList.contains("firstLoad")
      : true;

  return { add, remove, state };
}

import React from "react";
import useThemeColor from "../../hooks/useThemeColor";
import { Svg } from "../core";

function Vague({
  color = "primary",
  height = "7px",
  width = "52px",
  ...props
}) {
  const localColor = useThemeColor(color);

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 70 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.2857 9.35003C50.2397 9.1584 53.8657 7.36502 56.3706 4.44019C56.8479 4.98694 57.3792 5.52652 57.9637 6.04062C60.8927 8.61675 64.3842 9.92643 68.2999 9.11289C69.0973 8.94721 69.6051 8.16877 69.4406 7.37766C69.2758 6.58576 68.498 6.07312 67.6998 6.23896C64.841 6.83293 62.2418 5.88963 59.91 3.83869C59.2484 3.25677 58.6649 2.63086 58.1677 2.00583C57.9847 1.77582 57.8409 1.58165 57.7374 1.43433L57.5915 1.21625C57.0141 0.302607 55.6766 0.302935 55.1004 1.21948C53.1133 4.38012 49.6728 6.33468 45.9362 6.42888C42.281 6.24646 38.8615 4.30374 36.7937 1.18891C36.2031 0.299258 34.8886 0.315696 34.3204 1.21948C32.333 4.38059 28.8951 6.3347 25.1619 6.42888C21.5033 6.24642 18.0812 4.30327 16.0137 1.18891C15.4599 0.354837 14.2498 0.305373 13.6299 1.0925C13.5435 1.20215 13.3814 1.39341 13.1474 1.64751L12.8879 1.92357C12.2631 2.57542 11.5486 3.22798 10.7596 3.83517C7.97945 5.9748 5.11117 6.95236 2.3061 6.35773C1.50857 6.18867 0.728695 6.69817 0.560741 7.48941C0.392955 8.27985 0.897688 9.06032 1.69439 9.22921C5.53426 10.0432 9.22493 8.72405 12.5573 6.15948L13.0021 5.8076L13.0069 5.80364C13.6104 5.31302 14.1705 4.80355 14.6839 4.29035C17.2984 7.31656 21.0581 9.16957 25.1095 9.3628L25.1293 9.36375L25.5052 9.35032L25.5112 9.35003C29.4639 9.15834 33.0885 7.36403 35.5928 4.43775C38.2005 7.37668 41.9011 9.17272 45.8837 9.3628L45.9035 9.36375L46.2797 9.35028L46.2857 9.35003Z"
        fill={localColor}
      />
    </Svg>
  );
}

export { Vague };

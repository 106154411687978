import React from "react";
import { jsx, Button, useThemeUI } from "theme-ui";
import { motion } from "framer-motion";
import { darken } from "@theme-ui/color";
import useThemeColor from "../../hooks/useThemeColor";
import { navigate } from "../../helpers/navigate";

/** @jsx jsx */

const MotionButton = motion.custom(Button);

const CoreButton = React.forwardRef(
  (
    {
      variant = "primary",
      backgroundColor = "primary",
      outline = false,
      initial,
      to = null,
      onClick,
      disabled = false,
      download,
      external = false,
      ...props
    },
    forwardedRef
  ) => {
    const { theme } = useThemeUI();
    const localBackgroundColor = useThemeColor(backgroundColor);
    const handleOnClick = () => {
      if (!download) {
        if (to) {
          navigate(to, { external });
        }
        if (onClick) {
          onClick();
        }
      }
    };
    const buttonVariants = {
      hover: {
        backgroundColor: outline
          ? localBackgroundColor
          : darken(localBackgroundColor, 0.05)(theme),
        borderColor: outline ? localBackgroundColor : "initial",
      },
      tap: {
        backgroundColor: outline
          ? darken(localBackgroundColor, 0.05)(theme)
          : darken(localBackgroundColor, 0.1)(theme),
        borderColor: outline
          ? darken(localBackgroundColor, 0.05)(theme)
          : "initial",
      },
    };

    return (
      <MotionButton
        ref={forwardedRef}
        variant={variant}
        href={to}
        initial={{
          backgroundColor: outline ? "#FFF" : localBackgroundColor,
          border: outline ? "2px solid" : "initial",
          borderColor: outline ? localBackgroundColor : "initial",
          ...initial,
        }}
        whileHover={!disabled ? "hover" : "initial"}
        whileTap={!disabled ? "tap" : "initial"}
        variants={buttonVariants}
        transition={{ duration: 0.2 }}
        onClick={handleOnClick}
        disabled={disabled}
        download={download}
        {...props}
      />
    );
  }
);

export { CoreButton as Button };

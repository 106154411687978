import React from "react";
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Navigation, SideMenu } from "../SideMenu";
import { Button, Link } from "../core";

export default function NavMobile({ isSidebarOpen, toggleSidebarOpen }) {
  return (
    <SideMenu isOpen={isSidebarOpen}>
      <Navigation>
        <Navigation.Item>
          <Link
            variant="nav"
            onClick={toggleSidebarOpen}
            to="/qui-sommes-nous"
            sx={{ margin: "auto" }}
          >
            Qui sommes-nous ?
          </Link>
        </Navigation.Item>
        <Navigation.Item>
          <Link
            variant="nav"
            onClick={toggleSidebarOpen}
            to="/evenements"
            sx={{ margin: "auto" }}
          >
            Ramassages
          </Link>
        </Navigation.Item>
        <Navigation.Item>
          <Link
            variant="nav"
            onClick={toggleSidebarOpen}
            to="/sensibilisations"
            sx={{ margin: "auto" }}
          >
            Sensibilisations
          </Link>
        </Navigation.Item>
        <Navigation.Item>
          <Link
            variant="nav"
            onClick={toggleSidebarOpen}
            sx={{ margin: "auto" }}
            to="/partenaires"
          >
            Partenaires
          </Link>
        </Navigation.Item>
        <Navigation.Item>
          <Link
            variant="nav"
            onClick={toggleSidebarOpen}
            sx={{ margin: "auto" }}
            to="/contact"
          >
            Contact
          </Link>
        </Navigation.Item>
        {/*        <Navigation.Item>
          <Link
            variant="nav"
            onClick={toggleSidebarOpen}
            sx={{ margin: "auto" }}
            to="/boutique"
          >
            Boutique
          </Link>
        </Navigation.Item>*/}
        <Navigation.Item>
          <Link
            variant="nav"
            onClick={toggleSidebarOpen}
            sx={{ margin: "auto" }}
            to="/presse"
          >
            Presse
          </Link>
        </Navigation.Item>
        <Navigation.Item sx={{ marginTop: 5 }}>
          <Button
            id="btn-donation"
            sx={{
              width: "255px",
              textTransform: "uppercase",
              margin: "auto",
            }}
            to="/nous-aider"
            onClick={() => {
              if (isSidebarOpen) {
                toggleSidebarOpen();
              }
            }}
          >
            Nous aider
          </Button>
        </Navigation.Item>
      </Navigation>
    </SideMenu>
  );
}

import React from "react";
import useThemeColor from "../../hooks/useThemeColor";
import { Svg } from "../core";
import { useResponsiveValue } from "../../hooks/breakpoints";

function CallToActionVague2({
  color = "white",
  height = "201px",
  width = "598px",
  sx,
  ...props
}) {
  const localColor = useThemeColor(color);
  const responsiveWidth = useResponsiveValue(
    Array.isArray(width) ? width : [width]
  );
  return (
    <Svg
      width={responsiveWidth}
      height={height}
      viewBox="0 0 598 201"
      fill="none"
      sx={{
        display: "block",
        pointerEvents: "none",
        position: "relative",
        ...sx,
      }}
      {...props}
    >
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M598 0.041516V201H0.427735C44.1535 168.695 91.5827 152.595 139.735 154.015C166.619 154.807 193.592 160.956 220.574 167.108C242.618 172.134 264.668 177.161 286.679 179.268C370.843 187.326 453.914 151.71 525.339 76.9419C533.764 68.1231 542.159 58.7048 550.586 49.2498C566.148 31.7909 581.822 14.207 598 0.041516Z"
        fill={localColor}
      />
    </Svg>
  );
}

export { CallToActionVague2 };

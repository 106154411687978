import React, { useRef } from "react";
/** @jsx jsx */
import { jsx } from "theme-ui";
import { motion, AnimatePresence } from "framer-motion";
import { useDimensions } from "./useDimmentions";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(0px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const SideMenu = ({ isOpen, children }) => {
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
    >
      <motion.div
        variants={sidebar}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          width: "100vw",
          backgroundColor: "grey",
          zIndex: 99,
        }}
      />
      <AnimatePresence>{isOpen && children}</AnimatePresence>
    </motion.nav>
  );
};

export { SideMenu };

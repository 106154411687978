import { useState, useLayoutEffect } from "react";
import { useThemeUI } from "@theme-ui/core";

const defaultBreakpoints = [52, 64];

export const useBreakpointIndex = ({ defaultIndex = 0 } = {}) => {
  const context = useThemeUI();

  const breakpoints =
    (context.theme && context.theme.breakpoints) || defaultBreakpoints;

  if (typeof defaultIndex !== "number") {
    throw new TypeError(
      `Default breakpoint index should be a number. Got: ${defaultIndex}, ${typeof defaultIndex}`
    );
  } else if (defaultIndex < 0 || defaultIndex > breakpoints.length - 1) {
    throw new RangeError(
      `Default breakpoint index out of range. Theme has ${breakpoints.length} breakpoints, got index ${defaultIndex}`
    );
  }

  const [value, setValue] = useState(defaultIndex);
  useLayoutEffect(() => {
    const getIndex = () =>
      breakpoints.filter(
        (bp) => window.matchMedia(`screen and (min-width: ${bp})`).matches
      ).length;

    const onResize = () => {
      const newValue = getIndex();
      if (value !== newValue) {
        setValue(newValue);
      }
    };

    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [breakpoints, value]);

  return value;
};

export function useResponsiveValue(values, options) {
  const { theme } = useThemeUI();
  const array = typeof values === "function" ? values(theme) : values;
  const index = useBreakpointIndex(options);
  return getResponsiveStyle(
    array,
    index >= array.length ? array.length - 1 : index
  );
}

const getResponsiveStyle = (array, indexBreakpoint) =>
  array[indexBreakpoint] === null
    ? getResponsiveStyle(array, indexBreakpoint - 1)
    : array[indexBreakpoint];

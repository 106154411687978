import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import useThemeColor from "../../hooks/useThemeColor";
import { Svg } from "../core";

function TikTok({
  color = "white",
  colorHover,
  height = "18px",
  width = "15px",
  transition,
  sx,
  ...props
}) {
  const localColor = useThemeColor(color);
  const localColorHover = useThemeColor(colorHover ?? color);
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      fill: localColor,
      transition,
    });
  }, [localColor]);

  const handleOnMouseEnter = () => {
    if (localColorHover !== localColor) {
      controls.start({
        fill: localColorHover,
        transition,
      });
    }
  };

  const handleOnMouseLeave = () => {
    if (localColorHover !== localColor) {
      controls.start({
        fill: localColor,
        transition,
      });
    }
  };

  return (
    <Svg
      version="1.1"
      id="Calque_1"
      viewBox="131.017 191.543 349.991 409.012"
      width={width}
      height={height}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      {...props}
    >
      <g>
        <motion.path
          animate={controls}
          fill={localColor}
          d="M380.443,191.543h-58.58l-0.612,285.511c0,35.03-30.943,62.619-65.974,62.619s-63.403-28.396-63.403-63.403
		c0-35.006,28.397-63.402,63.403-63.402c2.62,0,5.141,0.465,7.687,0.783v-61.225c-2.546-0.171-5.092-0.392-7.687-0.392
		c-68.617,0-124.26,55.618-124.26,124.26c0,68.643,55.619,124.261,124.26,124.261s124.261-55.618,124.261-124.261V308.141
		c20.171,30.82,54.517,49.376,91.359,49.376c3.354-0.024,6.707-0.196,10.062-0.514h0.049v-68.054
		C428.326,285.081,385.977,244.053,380.443,191.543z"
        />
      </g>
    </Svg>
  );
}

export { TikTok };

import React, { useEffect, useState } from "react";
import { Flex, MotionLink, MotionFlex, Link, Visibility } from "../core";
import {
  useViewportScroll,
  useTransform,
  AnimateSharedLayout,
  motion,
  AnimatePresence,
  useAnimation,
} from "framer-motion";
/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Logo, Facebook, Instagram } from "../svg";
import { useFirstMountState } from "react-use";
import OldschoolButton from "../OldschoolButton";
import { maximumBlueGreen } from "../../gatsby-plugin-theme-ui/baseColors";
import { MenuToggle } from "../SideMenu";
import { useStaticQuery, graphql } from "gatsby";

const Header = ({ isDynamic = false, toggleSidebarOpen, isSidebarOpen }) => {
  const { theme } = useThemeUI();
  const [hovered, setHovered] = useState(0);

  const [isSmall, setIsSmall] = useState(isDynamic);
  const { scrollY } = useViewportScroll();
  const isSmallTransform = useTransform(scrollY, (value) => value < 80);
  const isFirstMount = useFirstMountState();
  const controls = useAnimation();
  const { site } = useStaticQuery(seoQuery);

  useEffect(() => {
    setIsSmall(isDynamic);

    isSmallTransform.clearListeners();
    isSmallTransform.onChange((current) => {
      if (isDynamic) {
        setIsSmall(current);
      }
    });
  }, [isDynamic]);

  const transition = { duration: isFirstMount ? 2 : 0.3 };

  const isHovered = (i) => i === hovered;

  useEffect(() => {
    controls.start(isSmall ? "transparent" : "light");
  }, [isSmall]);

  const variants = {
    transparent: {
      boxShadow: "0px 2px 34px 0px rgba(0,0,0,0)",
      backgroundColor: "rgba(0,0,0,0)",
    },
    light: {
      boxShadow: "0px 2px 34px 0px rgba(0,0,0,0.09)",
      backgroundColor: theme.colors.background,
    },
  };

  return (
    <MotionFlex
      as="nav"
      positions={["space-between", "center"]}
      transition={transition}
      initial={isSmall ? "transparent" : "light"}
      animate={controls}
      variants={variants}
      sx={{
        height: "82px",
        position: "fixed",
        width: "100%",
        zIndex: 100,
      }}
      role="navigation"
      aria-label="main-navigation"
    >
      <Visibility display={["visible", null, "hidden", null]}>
        <MenuToggle
          sx={{ marginLeft: 2, cursor: "pointer" }}
          toggle={toggleSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        />
      </Visibility>

      <Flex sx={{ marginLeft: [8, null, 2, 3] }}>
        <Link
          aria-label="Lien accueil"
          to="/"
          onClick={() => {
            if (isSidebarOpen) {
              toggleSidebarOpen();
            }
          }}
          sx={{ display: "flex" }}
        >
          <Logo
            height={["35px", null, null, "40px"]}
            colorTop={isSmall ? "white" : "primary"}
            colorBottom={isSmall ? "white" : "secondary"}
            transition={transition}
          />
        </Link>
      </Flex>

      <Flex>
        <Visibility display={["hidden", null, "visible", null]}>
          <AnimateSharedLayout>
            <Flex onMouseLeave={() => setHovered(0)}>
              <StyledLink
                onMouseEnter={() => setHovered(1)}
                isSmall={isSmall}
                to="/qui-sommes-nous"
              >
                Qui sommes-nous ?
                <AnimatePresence>
                  {isHovered(1) && (
                    <Underline isSmall={isSmall} transition={transition} />
                  )}
                </AnimatePresence>
              </StyledLink>
              <StyledLink
                onMouseEnter={() => setHovered(2)}
                isSmall={isSmall}
                to="/evenements"
              >
                Ramassages
                <AnimatePresence>
                  {isHovered(2) && (
                    <Underline isSmall={isSmall} transition={transition} />
                  )}
                </AnimatePresence>
              </StyledLink>
              <StyledLink
                onMouseEnter={() => setHovered(3)}
                isSmall={isSmall}
                to="/sensibilisations"
              >
                Sensibilisations
                <AnimatePresence>
                  {isHovered(3) && (
                    <Underline isSmall={isSmall} transition={transition} />
                  )}
                </AnimatePresence>
              </StyledLink>
              {/*              <StyledLink
                onMouseEnter={() => setHovered(2)}
                isSmall={isSmall}
                to="/nos-actions"
              >
                Nos actions
                <AnimatePresence>
                  {isHovered(2) && (
                    <Underline isSmall={isSmall} transition={transition} />
                  )}
                </AnimatePresence>
              </StyledLink>*/}
              <StyledLink
                onMouseEnter={() => setHovered(4)}
                isSmall={isSmall}
                to="/partenaires"
              >
                Partenaires
                <AnimatePresence>
                  {isHovered(4) && (
                    <Underline isSmall={isSmall} transition={transition} />
                  )}
                </AnimatePresence>
              </StyledLink>
              <StyledLink
                onMouseEnter={() => setHovered(5)}
                isSmall={isSmall}
                to="/contact"
              >
                Contact
                <AnimatePresence>
                  {isHovered(5) && (
                    <Underline isSmall={isSmall} transition={transition} />
                  )}
                </AnimatePresence>
              </StyledLink>
              {/*              <StyledLink
                onMouseEnter={() => setHovered(5)}
                isSmall={isSmall}
                to="/boutique"
              >
                Boutique
                <AnimatePresence>
                  {isHovered(5) && (
                    <Underline isSmall={isSmall} transition={transition} />
                  )}
                </AnimatePresence>
              </StyledLink>*/}
              <StyledLink
                onMouseEnter={() => setHovered(6)}
                isSmall={isSmall}
                to="/presse"
              >
                Presse
                <AnimatePresence>
                  {isHovered(6) && (
                    <Underline isSmall={isSmall} transition={transition} />
                  )}
                </AnimatePresence>
              </StyledLink>
            </Flex>
          </AnimateSharedLayout>
        </Visibility>
        <Visibility display={["visible", null, "hidden", "visible"]}>
          <Link
            aria-label="Lien facebook"
            to={site.siteMetadata.social.facebook}
            external
            sx={{ height: "18px", mx: 4 }}
          >
            <Facebook color={isSmall ? "white" : "black"} />
          </Link>
          <Link
            aria-label="Lien instagram"
            to={site.siteMetadata.social.instagram}
            external
            sx={{ height: "19px", marginRight: [3, null, 5] }}
          >
            <Instagram color={isSmall ? "white" : "black"} />
          </Link>
        </Visibility>
        <Visibility display={["hidden", null, "visible", null]}>
          <OldschoolButton
            id="btn-donation-old"
            to="/nous-aider"
            color={maximumBlueGreen[500]}
            colorPressed={maximumBlueGreen[700]}
            colorShadow={maximumBlueGreen[900]}
            sx={{
              marginRight: [0, null, 2, 3],
              marginLeft: [0, null, 1, 0],
            }}
          >
            Nous aider
          </OldschoolButton>
        </Visibility>
      </Flex>
    </MotionFlex>
  );
};

export default Header;

const Underline = ({ isSmall }) => {
  const { theme } = useThemeUI();
  return (
    <motion.div
      layoutId="underline"
      initial={{
        opacity: 0,
        backgroundColor: isSmall
          ? theme.colors["white"]
          : theme.colors["primary"],
      }}
      animate={{
        opacity: 1,
        backgroundColor: isSmall
          ? theme.colors["white"]
          : theme.colors["primary"],
      }}
      exit={{ opacity: 0 }}
      transition
      style={{
        width: "100%",
        height: isSmall ? "0.16em" : "4px",
        position: "absolute",
        bottom: isSmall ? "-6px" : "-31px",
        backgroundColor: "#FFF",
      }}
    />
  );
};

const StyledLink = ({ sx, isSmall, ...props }) => (
  <MotionLink
    initial={{
      color: isSmall ? "#FFF" : "#000",
    }}
    animate={{
      color: isSmall ? "#FFF" : "#000",
    }}
    sx={{
      mx: [1, null, null, 2],
      fontWeight: "bold",
      position: "relative",
      ...sx,
    }}
    {...props}
  />
);

const seoQuery = graphql`
  query MetaDataHeader {
    site {
      siteMetadata {
        social {
          instagram
          facebook
        }
      }
    }
  }
`;

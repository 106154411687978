import * as React from "react";
import { motion } from "framer-motion";
/** @jsx jsx */
import { jsx } from "theme-ui";
import classnames from "classnames";

const variants = {
  enter: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  exit: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Navigation = (props) => (
  <motion.ul
    initial="exit"
    animate="enter"
    exit="exit"
    variants={variants}
    sx={{
      padding: "25px 0px",
      position: "fixed",
      top: "100px",
      width: "100%",
      margin: 0,
      zIndex: 99,
    }}
    {...props}
  />
);

const variantsItem = {
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Item = ({ sx, className, ...props }) => {
  return (
    <motion.li
      className={classnames("noTapHighlight", className)}
      variants={variantsItem}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      sx={{
        listStyle: "none",
        margin: 0,
        marginBottom: "20px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        padding: 0,
        zIndex: 99,
        ...sx,
      }}
      {...props}
    />
  );
};

Navigation.Item = Item;

export { Navigation };

import React from "react";
import { Textarea } from "theme-ui";
import { motion } from "framer-motion";

const CoreTextarea = React.forwardRef(({ name, ...props }, forwardedRef) => (
  <Textarea ref={forwardedRef} id={name} name={name} {...props} />
));

const MotionTextarea = motion.custom(CoreTextarea);
export { CoreTextarea as Textarea, MotionTextarea };

import React from "react";
import { Text } from "theme-ui";
import { motion } from "framer-motion";

const CoreText = React.forwardRef(
  (
    {
      as = "p",
      sx,
      uppercase = false,
      preLine = false,
      fontSize,
      fontWeight,
      lineHeight,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <Text
        ref={forwardedRef}
        as={as}
        sx={{
          textTransform: uppercase ? "uppercase" : "initial",
          whiteSpace: preLine ? "pre-line" : "initial",
          fontSize,
          fontWeight,
          lineHeight,
          ...sx,
        }}
        {...props}
      />
    );
  }
);

const MotionText = motion.custom(CoreText);
export { CoreText as Text, MotionText };

import React from "react";
import { motion } from "framer-motion";
/** @jsx jsx */
import { jsx, Input } from "theme-ui";

const CoreInput = React.forwardRef(
  ({ id, name, type = "text", ...props }, forwardedRef) => (
    <Input
      ref={forwardedRef}
      id={id ?? name}
      name={name}
      type={type}
      {...props}
    />
  )
);

const MotionInput = motion.custom(CoreInput);
export { CoreInput as Input, MotionInput };

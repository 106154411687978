import React from "react";
import Layout from "./src/components/layout/Layout";
import "./src/styles/global.css";
import { firstLoad } from "./src/helpers/load";

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry = () => {
  firstLoad().add();
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const scrollTo = new URLSearchParams(location.search).get("scrollTo");

  if (scrollTo) {
    //Force calculation to happen at the end
    setTimeout(() => {
      let topOfElement = document.querySelector(`#${scrollTo}`);
      window.scroll({
        top: topOfElement.offsetTop - 82,
      });
    }, 0);
  } else {
    window.scrollTo(0, 0);
  }

  return false;
};

import React from "react";

const InputTextContext = React.createContext(undefined);

function useInputTextContext() {
  const context = React.useContext(InputTextContext);
  if (context === undefined) {
    throw new Error(
      "InputTextContext must bed used within a InputTextContext.Provider"
    );
  }

  return context;
}

export { InputTextContext, useInputTextContext };

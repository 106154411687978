import React from "react";
import { Container } from "theme-ui";
import { motion } from "framer-motion";

const CoreContainer = React.forwardRef(
  ({ variant = "container", ...props }, forwardedRef) => (
    <Container ref={forwardedRef} variant={variant} {...props} />
  )
);

const MotionContainer = motion.custom(CoreContainer);
export { CoreContainer as Container, MotionContainer };

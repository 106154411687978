import * as React from "react";
import { motion } from "framer-motion";
import classnames from "classnames";

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle, isSidebarOpen, className, ...props }) => (
  <svg
    className={classnames("noTapHighlight", className)}
    width="23"
    height="23"
    viewBox="0 0 23 23"
    onClick={toggle}
    {...props}
  >
    <Path
      initial={{
        d: "M 2 2.5 L 20 2.5",
      }}
      animate={{
        d: isSidebarOpen ? "M 3 16.5 L 17 2.5" : "M 2 2.5 L 20 2.5",
      }}
    />
    <Path
      d="M 2 9.423 L 15 9.423"
      initial={{ opacity: 1 }}
      animate={{
        opacity: isSidebarOpen ? 0 : 1,
      }}
      transition={{ duration: 0.1 }}
    />
    <Path
      initial={{
        d: "M 2 16.346 L 20 16.346",
      }}
      animate={{
        d: isSidebarOpen ? "M 3 2.5 L 17 16.346" : "M 2 16.346 L 20 16.346",
      }}
    />
  </svg>
);

import React from "react";
import { motion } from "framer-motion";
/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import classnames from "classnames";

const Svg = React.forwardRef(
  ({ className, variant = "svg", sx, ...props }, forwardedRef) => {
    const { theme } = useThemeUI();
    return (
      <svg
        className={classnames("noTapHighlight", className)}
        ref={forwardedRef}
        sx={{ ...theme.styles[variant], ...sx }}
        {...props}
      />
    );
  }
);

const MotionSvg = motion.custom(Svg);
export { Svg, MotionSvg };

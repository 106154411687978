import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import useThemeColor from "../../hooks/useThemeColor";
import { Svg } from "../core";

const Arrow = React.forwardRef(
  (
    { color = "primary", height = "10px", width = "6px", transition, ...props },
    ref
  ) => {
    const localColor = useThemeColor(color);
    const controls = useAnimation();

    useEffect(() => {
      controls.start({
        fill: localColor,
        transition,
      });
    }, [localColor]);

    return (
      <Svg
        ref={ref}
        width={width}
        height={height}
        viewBox="0 0 6 10"
        version="1.1"
        {...props}
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <motion.g
            id="Icones"
            transform="translate(-1170.000000, -369.000000)"
            animate={controls}
            fill={localColor}
          >
            <g id="chevron-back" transform="translate(1171.000000, 370.000000)">
              <path d="M3.3314897,-0.743702887 C3.74222547,-1.11291093 4.37449485,-1.07924606 4.74370289,-0.668510296 C5.08451031,-0.289369591 5.08204141,0.278533484 4.75659016,0.654051875 L4.6685103,0.743702887 L1.324,3.749 L4.66858244,6.75636197 C5.04769008,7.09720617 5.1054848,7.66216614 4.8217096,8.07009286 L4.74363803,8.16858244 C4.40279383,8.54769008 3.83783386,8.6054848 3.42990714,8.3217096 L3.33141756,8.24363803 L-0.556172965,4.74842263 C-0.83931047,4.49343612 -1,4.12993776 -1,3.74965909 C-1,3.41691525 -0.876972079,3.09701884 -0.656072581,2.85100669 L-0.555482573,2.75027439 L3.3314897,-0.743702887 Z"></path>
            </g>
          </motion.g>
        </g>
      </Svg>
    );
  }
);

export { Arrow };

import * as React from "react";
import { useEffect, useState } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import useThemeColor from "../../hooks/useThemeColor";

const tickVariants = {
  checked: {
    pathLength: 1,
    transition: {
      delay: 0.1,
      duration: 0.2,
    },
  },
  unchecked: { pathLength: 0 },
};

export const Check = ({ color = "white", height = "18px", width = "24px" }) => {
  const [isChecked, setIsChecked] = useState(false);
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);
  const localColor = useThemeColor(color);

  useEffect(() => {
    setIsChecked(true);
  }, []);

  return (
    <motion.svg
      initial={false}
      animate={isChecked ? "checked" : "unchecked"}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="17.928 19.334 9.145 6.686"
    >
      <motion.path
        fill="none"
        stroke={localColor}
        d="M18.281,22.5l2.813,2.813l5.625-5.625"
        variants={tickVariants}
        style={{ pathLength, opacity }}
        custom={isChecked}
      />
    </motion.svg>
  );
};

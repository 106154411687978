import React from "react";
import { Image, AspectImage } from "theme-ui";
import { motion } from "framer-motion";
import classnames from "classnames";

const CoreImage = React.forwardRef(
  ({ className, variant = "default", ratio = null, ...props }, forwardedRef) =>
    ratio ? (
      <AspectImage
        className={classnames("noTapHighlight", className)}
        ref={forwardedRef}
        variant={variant}
        ratio={ratio}
        {...props}
      />
    ) : (
      <Image
        className={classnames("noTapHighlight", className)}
        ref={forwardedRef}
        variant={variant}
        {...props}
      />
    )
);

const MotionImage = motion.custom(CoreImage);
export { CoreImage as Image, MotionImage };

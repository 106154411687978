import React from "react";
import { Heading } from "theme-ui";
import { motion } from "framer-motion";
import { useResponsiveValue } from "../../hooks/breakpoints";

const CoreHeading = React.forwardRef(
  (
    {
      fontFamily,
      fontWeight,
      fontSize,
      sx,
      as,
      variant = [],
      uppercase = false,
      preLine = false,
      ...props
    },
    forwardedRef
  ) => {
    const localVariant = useResponsiveValue(
      Array.isArray(variant) ? variant : [variant]
    );
    return (
      <Heading
        ref={forwardedRef}
        variant={localVariant || as}
        as={as}
        sx={{
          textTransform: uppercase ? "uppercase" : "initial",
          fontSize,
          fontWeight,
          whiteSpace: preLine ? "pre-line" : "initial",
          ...sx,
        }}
        {...props}
      />
    );
  }
);

const MotionHeading = motion.custom(CoreHeading);
export { CoreHeading as Heading, MotionHeading };

import React from "react";
import { motion } from "framer-motion";
/** @jsx jsx */
import { jsx, Badge } from "theme-ui";

const CoreBadge = React.forwardRef(
  ({ type = "text", variant = "default", ...props }, forwardedRef) => (
    <Badge ref={forwardedRef} variant={variant} {...props} />
  )
);

const MotionBadge = motion.custom(CoreBadge);
export { CoreBadge as Badge, MotionBadge };

import React from "react";
import { Box, jsx } from "theme-ui";
import { motion } from "framer-motion";
import { Flex } from "./Flex";
import { useThemeUI } from "@theme-ui/core";

/** @jsx jsx */

const Card = React.forwardRef(
  ({ sx, variant = "default", id, ...props }, ref) => {
    const { theme } = useThemeUI();
    return (
      <Box
        id={`card-${id}`}
        ref={ref}
        sx={{
          scrollMarginTop: "82px",
          ...theme.cards[variant],
          ...sx,
        }}
        {...props}
      />
    );
  }
);

const MotionCard = motion.custom(Card);

const Content = React.forwardRef(({ sx, ...props }, ref) => (
  <Flex
    ref={ref}
    direction="column"
    sx={{ flex: "1 1 auto", ...sx }}
    {...props}
  />
));

const MotionContent = motion.custom(Content);

Card.Content = Content;
Card.MotionContent = MotionContent;

export { Card, MotionCard };

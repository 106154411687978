import React from "react";
import { Box, jsx } from "theme-ui";
import { motion } from "framer-motion";

/** @jsx jsx */

const Br = ({ sx, height = 2, width = 50, color = "primary", ...props }) => (
  <Box
    sx={{
      display: "inline-block",
      width,
      borderTop: `${height}px solid`,
      borderColor: color,
      ...sx,
    }}
    {...props}
  />
);

const MotionBr = motion.custom(Br);

export { Br, MotionBr };

import React, { useRef, useEffect } from "react";
import { useInputTextContext } from "../InputContext";
import { Input, jsx } from "theme-ui";
/** @jsx jsx */

const BaseInput = React.forwardRef(({ style, className }, forwardedRef) => {
  const { value, name, isFocused, onChange, ...params } = useInputTextContext();
  const inputRef = useRef();

  useEffect(() => {
    isFocused && inputRef.current.focus();
  }, [isFocused]);

  const handleChange = (e) => {
    onChange && onChange(e.target.value);
  };

  return (
    <Input
      ref={inputRef}
      className={className}
      id={name}
      name={name}
      variant={null}
      onChange={handleChange}
      sx={{
        backgroundColor: "transparent",
        border: 0,
        color: "white",
        padding: 0,
        outline: 0,
        "&::placeholder": {
          color: "white",
        },
      }}
      {...params}
    />
  );
});

export { BaseInput };

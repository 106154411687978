import MasonryBase from "react-masonry-css";
import React from "react";
/** @jsx jsx */
import { Box, jsx, useThemeUI } from "theme-ui";

const Masonry = ({
  sx,
  gutter = 0,
  breakpointCols = 3,
  children,
  ...props
}) => {
  const { theme } = useThemeUI();
  return (
    <MasonryBase
      breakpointCols={breakpointCols}
      sx={{
        display: "flex",
        marginLeft: `-${theme.space[gutter]}px` /* gutter size offset */,
        width: "auto",
        ...sx,
      }}
      {...props}
    >
      {React.Children.map(children, (child) => (
        <Box
          sx={{
            paddingLeft: gutter /* gutter size */,
            backgroundClip: "padding-box",
          }}
        >
          {React.isValidElement(child)
            ? React.cloneElement(child, { gutter })
            : child}
        </Box>
      ))}
    </MasonryBase>
  );
};

const Block = ({ sx, gutter, ...props }) => (
  <div sx={{ marginBottom: gutter, ...sx }} {...props} />
);

Masonry.Block = Block;

export { Masonry };

import React from "react";
import { jsx, Box } from "theme-ui";
import { Button, Flex, Text, Link } from "./core";
/** @jsx jsx */
import { useCookies } from "react-cookie";

const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(["cmc-rgpd"]);

  return cookies["cmc-rgpd"] === undefined ? (
    <Flex
      positions={[["center", "center"], null, ["center", "flex-end"]]}
      sx={{
        position: "fixed",
        top: 0,
        height: "100%",
        width: "100%",
        backgroundColor: ["rgba(0, 0, 0, 0.3)", null, "transparent"],
        zIndex: 100,
        px: [3, null, 0],
        pointerEvents: "none",
      }}
    >
      <Flex
        backgroundColor="grey"
        direction={["column", null, "row"]}
        sx={{
          width: "100%",
          padding: [4, null, 2],
          textAlign: ["center", null, "left"],
          pointerEvents: "auto",
          borderRadius: [8, null, 0],
        }}
      >
        <Box sx={{ paddingRight: [0, null, 4], paddingBottom: [4, null, 0] }}>
          <Text variant="p1" fontSize={14} lineHeight={"24px"}>
            Ce site web utilise des cookies pour fournir les fonctionnalités
            nécessaires et analyser son trafic. En l'utilisant, vous acceptez
            notre{" "}
            <Link to="/politique-de-confidentialite">
              politique de confidentialité
            </Link>
            .
          </Text>
        </Box>

        <Box sx={{ minWidth: "max-content" }}>
          <Button
            sx={{
              textTransform: "uppercase",
              marginLeft: 1,
              minWidth: "130px",
            }}
            onClick={() => {
              setCookie("cmc-rgpd", true);
            }}
          >
            OK
          </Button>
        </Box>
      </Flex>
    </Flex>
  ) : null;
};

export default CookieBanner;

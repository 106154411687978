import React from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";
/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import classnames from "classnames";

const removeSiteUrl = (url) => {
  return url && url.replace("https://www.cleanmycalanques.fr", "");
};

const CoreLink = React.forwardRef(
  (
    { className, sx, to, variant = "a", external = false, ...props },
    forwardedRef
  ) => {
    const { theme } = useThemeUI();
    const styles = theme.links[variant] ?? {};
    return external ? (
      <a
        className={classnames("noTapHighlight", className)}
        ref={forwardedRef}
        href={to}
        target="_blank"
        rel="noreferrer"
        sx={{
          ...styles,
          ...sx,
        }}
        {...props}
      />
    ) : (
      <Link
        className={classnames("noTapHighlight", className)}
        ref={forwardedRef}
        to={removeSiteUrl(to)}
        sx={{
          ...styles,
          ...sx,
        }}
        {...props}
      />
    );
  }
);

const MotionLink = motion.custom(CoreLink);
export { CoreLink as Link, MotionLink };

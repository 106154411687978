import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import useThemeColor from "../../hooks/useThemeColor";
import { Svg } from "../core";

function Linkedin({
  color = "white",
  colorHover,
  height = "18px",
  width = "18px",
  transition,
  sx,
  ...props
}) {
  const localColor = useThemeColor(color);
  const localColorHover = useThemeColor(colorHover ?? color);
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      fill: localColor,
      transition,
    });
  }, [localColor]);

  const handleOnMouseEnter = () => {
    if (localColorHover !== localColor) {
      controls.start({
        fill: localColorHover,
        transition,
      });
    }
  };

  const handleOnMouseLeave = () => {
    if (localColorHover !== localColor) {
      controls.start({
        fill: localColor,
        transition,
      });
    }
  };

  return (
    <Svg
      viewBox="0 5 1036 990"
      width={width}
      height={height}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      {...props}
    >
      <motion.path
        animate={controls}
        fill={localColor}
        d="M0 120c0-33.334 11.667-60.834 35-82.5C58.333 15.833 88.667 5 126 5c36.667 0 66.333 10.666 89 32 23.333 22 35 50.666 35 86 0 32-11.333 58.666-34 80-23.333 22-54 33-92 33h-1c-36.667 0-66.333-11-89-33S0 153.333 0 120zm13 875V327h222v668H13zm345 0h222V622c0-23.334 2.667-41.334 8-54 9.333-22.667 23.5-41.834 42.5-57.5 19-15.667 42.833-23.5 71.5-23.5 74.667 0 112 50.333 112 151v357h222V612c0-98.667-23.333-173.5-70-224.5S857.667 311 781 311c-86 0-153 37-201 111v2h-1l1-2v-95H358c1.333 21.333 2 87.666 2 199 0 111.333-.667 267.666-2 469z"
      />
    </Svg>
  );
}

export { Linkedin };

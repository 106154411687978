import React, { useImperativeHandle, useRef } from "react";
import { BaseInput } from "./BaseInput";

const Field = React.forwardRef(({ sx, ...props }, forwardedRef) => {
  return (
    <BaseInput
      ref={forwardedRef}
      sx={{ ...sx, flexGrow: 1, width: "initial" }}
      {...props}
    />
  );
});

export { Field };

import React, { Children, cloneElement, useMemo } from "react";
/** @jsx jsx */
import { jsx, Grid } from "theme-ui";
import { motion } from "framer-motion";

const CoreGrid = React.forwardRef(
  ({ sx, reverse = false, children, ...props }, forwardedRef) => {
    const coundChildren = useMemo(() => Children.count(children), [children]);
    return (
      <Grid
        ref={forwardedRef}
        sx={{
          gridAutoFlow: reverse ? "dense" : "row",
          ...sx,
        }}
        {...props}
      >
        {Children.map(children, (child, index) =>
          cloneElement(child, {
            sx: { gridColumn: reverse ? coundChildren - index : index + 1 },
          })
        )}
      </Grid>
    );
  }
);

const MotionGrid = motion.custom(CoreGrid);
export { CoreGrid as Grid, MotionGrid };

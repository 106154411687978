import React from "react";
/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import useThemeColor from "../../../hooks/useThemeColor";

const Chevron = React.forwardRef(
  (
    { isOpen, color = "primary", width = "30px", height = "4px", ...props },
    forwardedRef
  ) => {
    const localColor = useThemeColor(color);
    return (
      <Box
        sx={{
          backgroundColor: "white",
          height,
          width,
          display: "block",
          position: "relative",
          cursor: "pointer",
          borderRadius: "4px",
        }}
        ref={forwardedRef}
        {...props}
      >
        <Bar
          position="left"
          color={localColor}
          isOpen={isOpen}
          height={height}
          width={`${parseInt(width) / 2}px`}
        />
        <Bar
          position="right"
          color={localColor}
          isOpen={isOpen}
          height={height}
          width={`${parseInt(width) / 2}px`}
        />
      </Box>
    );
  }
);

export { Chevron };

const Bar = React.forwardRef(
  ({ position, isOpen, color, width, height }, forwardedRef) => {
    const isLeft = position === "left";
    return (
      <span
        ref={forwardedRef}
        sx={{
          position: "absolute",
          backgroundColor: "transparent",
          top: 0,
          left: isLeft ? 0 : `calc(${width}* 0.65)`,
          width,
          height,
          display: "block",
          transform: `rotate(${isLeft ? "40deg" : "-40deg"})`,
          float: "right",
          borderRadius: "2px",
          " &:after": {
            content: '""',
            backgroundColor: color,
            width,
            height,
            display: "block",
            float: "right",
            borderRadius: isLeft
              ? `calc(${height} * 0.8) ${height} ${height} calc(${height} * 0.8)`
              : `${height} calc(${height} * 0.8) calc(${height} * 0.8) ${height}`,
            transition: "all 0.5s cubic-bezier(.25,1.7,.35,.8)",
            zIndex: -1,
            transformOrigin: isOpen && "center center",
            transform: isOpen && `rotate(${isLeft ? "-80deg" : "80deg"})`,
          },
        }}
      />
    );
  }
);

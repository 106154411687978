import React, { Children, cloneElement, useCallback } from "react";
import { motion } from "framer-motion";
import { Flex } from "./Flex";
import { useResponsiveValue } from "../../hooks/breakpoints";
/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";

const FlexGrid = React.forwardRef(
  (
    {
      children,
      columns,
      gutter = 3,
      gutterX,
      gutterY,
      positions = ["flex-start", "center"],
      ...props
    },
    forwardedRef
  ) => {
    const { theme } = useThemeUI();
    const responsiveGutter = useResponsiveValue(
      Array.isArray(gutter) ? gutter : [gutter]
    );
    const responsiveGutterX = useResponsiveValue(
      Array.isArray(gutterX) ? gutterX : [gutterX]
    );
    const responsiveGutterY = useResponsiveValue(
      Array.isArray(gutterY) ? gutterY : [gutterY]
    );

    const localGutterX =
      responsiveGutterX != null && responsiveGutterX !== undefined
        ? responsiveGutterX
        : responsiveGutter;
    const localGutterY =
      responsiveGutterY != null && responsiveGutterY !== undefined
        ? responsiveGutterY
        : responsiveGutter;

    const getPourcentage = (localColumns) =>
      Array.isArray(localColumns)
        ? localColumns.map((columnItem) =>
            columnItem === null ? null : `${(100 * columnItem) / 12}%`
          )
        : `${(100 * localColumns) / 12}%`;

    const getFlex = (localColumns) =>
      Array.isArray(localColumns)
        ? localColumns.map((columnItem) =>
            columnItem === null ? null : `0 0 ${(100 * columnItem) / 12}%`
          )
        : `0 0 ${(100 * localColumns) / 12}%`;

    const column = useResponsiveValue(columns);
    const widthPourcentage = getPourcentage(column);
    const getGutter = useCallback(
      (coordonnee) => {
        let _gutter = 0;
        switch (coordonnee) {
          case "x":
            _gutter = widthPourcentage === "100%" ? 0 : localGutterX;
            break;
          case "y":
            _gutter = localGutterY;
            break;
        }
        return theme.space[_gutter];
      },
      [localGutterY, localGutterX, widthPourcentage]
    );

    return (
      <Flex
        ref={forwardedRef}
        sx={{
          marginTop: `-${getGutter("y")}px`,
          marginLeft: `-${getGutter("x")}px`,
          flexWrap: "wrap",
        }}
        positions={positions}
        {...props}
      >
        {Children.map(children, (child, index) =>
          cloneElement(child, {
            ...child.props,
            sx: {
              flex: getFlex(columns),
              paddingTop: `${getGutter("y")}px`,
              paddingLeft: `${getGutter("x")}px`,
              minWidth: getPourcentage(columns),
              ...child.props.sx,
            },
          })
        )}
      </Flex>
    );
  }
);

const MotionFlexGrid = motion.custom(FlexGrid);

export { FlexGrid, MotionFlexGrid };

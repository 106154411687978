import React from "react";
import useThemeColor from "../../hooks/useThemeColor";
import { Svg } from "../core";
import { useResponsiveValue } from "../../hooks/breakpoints";

function CallToActionVague1({
  color = "#0C4B66",
  height = "211px",
  width = "453px",
  sx,
  ...props
}) {
  const localColor = useThemeColor(color);
  const responsiveWidth = useResponsiveValue(
    Array.isArray(width) ? width : [width]
  );
  return (
    <Svg
      width={responsiveWidth}
      height={height}
      viewBox="0 0 453 211"
      fill="none"
      sx={{
        display: "block",
        pointerEvents: "none",
        position: "relative",
        ...sx,
      }}
      {...props}
    >
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M452.973 0C422.746 34.351 386.962 56.4274 346.957 65.0222C318.972 71.0348 289.097 70.5407 259.212 70.0464C234.796 69.6426 210.373 69.2387 186.971 72.3835C111.574 82.5149 47.3829 130.239 0 210.618V0L452.973 0Z"
        fill={localColor}
      />
    </Svg>
  );
}

export { CallToActionVague1 };

import React from "react";
import { Svg } from "../core";

function Player({ height = "80px", width = "80px", ...props }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.9997 0.833252C32.2533 0.833252 24.6808 3.13034 18.2399 7.43403C11.7989 11.7377 6.77884 17.8547 3.81441 25.0115C0.849978 32.1683 0.0743475 40.0434 1.5856 47.641C3.09686 55.2385 6.82712 62.2174 12.3047 67.6949C17.7822 73.1725 24.7611 76.9028 32.3587 78.414C39.9562 79.9253 47.8314 79.1496 54.9881 76.1852C62.1449 73.2208 68.2619 68.2007 72.5656 61.7598C76.8693 55.3188 79.1664 47.7464 79.1664 39.9999C79.1664 34.8565 78.1533 29.7634 76.185 25.0115C74.2167 20.2596 71.3317 15.9419 67.6947 12.3049C64.0577 8.66794 59.74 5.78295 54.9881 3.81464C50.2362 1.84633 45.1431 0.833252 39.9997 0.833252ZM55.6664 42.5066L32.8322 56.7633C32.388 57.0636 31.8701 57.2365 31.3346 57.2635C30.7991 57.2904 30.2664 57.1703 29.7943 56.9161C29.3222 56.6619 28.9287 56.2833 28.6564 55.8214C28.3842 55.3595 28.2435 54.8319 28.2497 54.2957V25.7041C28.2528 25.1802 28.3959 24.6667 28.6642 24.2168C28.9326 23.7668 29.3164 23.3969 29.7758 23.1452C30.2353 22.8935 30.7537 22.7692 31.2773 22.7853C31.8009 22.8014 32.3107 22.9572 32.7539 23.2366L55.6664 37.4933C56.0868 37.7625 56.4328 38.1333 56.6724 38.5713C56.9121 39.0093 57.0377 39.5006 57.0377 39.9999C57.0377 40.4992 56.9121 40.9905 56.6724 41.4285C56.4328 41.8666 56.0868 42.2373 55.6664 42.5066Z"
        fill="white"
      />
    </Svg>
  );
}

export { Player };

import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import useThemeColor from "../../hooks/useThemeColor";
import { Svg } from "../core";

function Twitter({
  color = "white",
  colorHover,
  height = "18px",
  width = "22px",
  transition,
  sx,
  ...props
}) {
  const localColor = useThemeColor(color);
  const localColorHover = useThemeColor(colorHover ?? color);
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      fill: localColor,
      transition,
    });
  }, [localColor]);

  const handleOnMouseEnter = () => {
    if (localColorHover !== localColor) {
      controls.start({
        fill: localColorHover,
        transition,
      });
    }
  };

  const handleOnMouseLeave = () => {
    if (localColorHover !== localColor) {
      controls.start({
        fill: localColor,
        transition,
      });
    }
  };

  return (
    <Svg
      version="1.1"
      id="svg3626"
      viewBox="2.49 148.634 607.512 494.292"
      width={width}
      height={height}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      {...props}
    >
      <g id="layer1" transform="translate(-539.17946,-568.85777)">
        <motion.path
          animate={controls}
          fill={localColor}
          id="path3611"
          d="M732.409,1211.784c229.5,0,354.959-190.128,354.959-354.959c0-5.304-0.203-10.812-0.407-16.116
		c24.275-17.544,45.491-39.576,62.22-64.668c-22.439,9.996-46.308,16.524-71.604,19.584c25.703-15.504,45.491-39.78,54.876-68.952
		c-24.072,14.28-50.796,24.684-79.152,30.192c-22.848-24.276-55.08-39.372-90.983-39.372c-68.952,0-124.645,55.896-124.645,124.644
		c0,9.792,1.02,19.38,3.264,28.356c-103.632-5.304-195.636-54.876-257.04-130.356c-10.812,18.36-16.932,39.78-16.932,62.628
		c0,43.248,22.032,81.396,55.488,103.836c-20.4-0.612-39.78-6.324-56.508-15.504c0,0.612,0,1.02,0,1.632
		c0,60.384,43.044,110.771,100.164,122.196c-10.404,2.855-21.42,4.487-32.844,4.487c-7.956,0-15.912-0.815-23.46-2.243
		c15.912,49.571,62.016,85.68,116.484,86.7c-42.636,33.455-96.492,53.447-154.836,53.447c-9.996,0-19.992-0.611-29.784-1.632
		C596.342,1191.18,662.03,1211.784,732.409,1211.784"
        />
      </g>
    </Svg>
  );
}

export { Twitter };

import React from "react";
import useThemeColor from "../../hooks/useThemeColor";
import { Svg } from "../core";

function BackgroundVague({
  color = "white",
  height = "232px",
  width = "1440px",
  sx,
  ...props
}) {
  const localColor = useThemeColor(color);
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 1440 232"
      version="1.1"
      sx={{
        display: "block",
        pointerEvents: "none",
        position: "relative",
        ...sx,
      }}
      {...props}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Clean-My-Calanques---PRESSE"
          transform="translate(0.000000, -2314.000000)"
          fill={localColor}
        >
          <g id="Group" transform="translate(-8.000000, 2314.000000)">
            <path
              d="M1457,220.371733 C1343.67452,170.916852 1218.90475,147.896954 1095.38694,153.652867 C996.722846,158.250386 899.719518,180.83382 801.265083,188.735994 C631.984383,202.323466 459.329538,171.477268 305.235503,100.115059 C206.846446,54.5515425 107.975691,-8.90999548 0,1.04228094 L0,232 L1456.86173,232 L1457,220.371733 Z"
              id="Fill-1"
              transform="translate(728.500000, 116.000000) scale(-1, -1) translate(-728.500000, -116.000000) "
            ></path>
          </g>
        </g>
      </g>
    </Svg>
  );
}

export { BackgroundVague };

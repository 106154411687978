import React from "react";
import useThemeColor from "../../hooks/useThemeColor";
import { Svg } from "../core";

function Download({
  color = "white",
  height = "24px",
  width = "24px",
  ...props
}) {
  const localColor = useThemeColor(color);

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_510_3524)">
        <path
          d="M8.61636 1.74099C8.61662 1.35544 8.76989 0.985767 9.04251 0.713147C9.31514 0.440526 9.68481 0.287252 10.0704 0.286987H13.4624C13.8477 0.287517 14.2171 0.440908 14.4896 0.7135C14.762 0.986091 14.9151 1.35562 14.9154 1.74099V9.96899C14.9154 10.0353 14.9417 10.0989 14.9886 10.1458C15.0355 10.1926 15.0991 10.219 15.1654 10.219H18.0654C18.3049 10.2004 18.5441 10.2581 18.7488 10.3838C18.9535 10.5095 19.1132 10.6967 19.205 10.9187C19.2968 11.1407 19.316 11.3861 19.2598 11.6196C19.2037 11.8532 19.075 12.063 18.8924 12.219L12.7924 18.319C12.5154 18.5833 12.1472 18.7308 11.7644 18.7308C11.3815 18.7308 11.0133 18.5833 10.7364 18.319L4.63636 12.219C4.45301 12.0633 4.32377 11.8534 4.26722 11.6197C4.21067 11.3859 4.22972 11.1402 4.32164 10.9179C4.41355 10.6956 4.57359 10.5082 4.77874 10.3826C4.9839 10.257 5.22359 10.1997 5.46336 10.219H8.36336C8.39622 10.2191 8.42879 10.2127 8.45918 10.2002C8.48957 10.1877 8.51719 10.1693 8.54043 10.1461C8.56367 10.1228 8.58208 10.0952 8.59459 10.0648C8.60711 10.0344 8.61349 10.0019 8.61336 9.96899L8.61636 1.74099Z"
          fill="white"
        />
        <path
          d="M0 19.677C0.00105897 20.7468 0.426514 21.7725 1.18299 22.529C1.93947 23.2855 2.96518 23.7109 4.035 23.712H19.965C21.0348 23.7109 22.0605 23.2855 22.817 22.529C23.5735 21.7725 23.9989 20.7468 24 19.677V17.8C23.9909 17.4813 23.8579 17.1787 23.6292 16.9565C23.4006 16.7343 23.0943 16.61 22.7755 16.61C22.4567 16.61 22.1504 16.7343 21.9218 16.9565C21.6931 17.1787 21.5601 17.4813 21.551 17.8V19.674C21.5505 20.0945 21.3832 20.4976 21.0859 20.7949C20.7886 21.0922 20.3855 21.2595 19.965 21.26H4.035C3.61453 21.2595 3.21143 21.0922 2.91411 20.7949C2.6168 20.4976 2.44953 20.0945 2.449 19.674V17.8C2.43989 17.4813 2.30689 17.1787 2.07824 16.9565C1.8496 16.7343 1.54333 16.61 1.2245 16.61C0.90567 16.61 0.599402 16.7343 0.370757 16.9565C0.142112 17.1787 0.0091085 17.4813 0 17.8L0 19.677Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_510_3524">
          <rect width="24" height="24" fill={localColor} />
        </clipPath>
      </defs>
    </Svg>
  );
}

export { Download };
